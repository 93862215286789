.details {
  display: flex;
  justify-content: center;
  flex-flow: column;
}

.details > * {
  margin: 0 auto;
}

.details > *:first-child {
  margin-bottom: 1rem;
}
