.Layout {
  position: relative;
  margin: 0 auto;
  max-width: 1640px;
  width: 100%;
  /* padding: 1rem 0; */
  min-height: calc(100vh - 4rem);
}

.layout--internal {
  padding: 1rem 0;
  max-width: 1364px;
  width: 100%;
  margin: 0 auto;
}

.main {
  background-color: var(--blue);
  background-image: url(/background-full.webp);
  background-attachment: fixed;
  background-size: cover;
}
