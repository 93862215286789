.AuthenticationLayout {
  margin: 4rem auto 0 auto;
  max-width: 40rem;
  width: 100%;
}

.Card {
  max-width: 40rem;
  width: 100%;
}

.Heading {
  display: block;
  margin-bottom: 2.8rem;
  text-align: center;
}

.Card--content {
  padding: 2rem;
}
